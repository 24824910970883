import React from "react"
import { graphql } from "gatsby"
import SEO from "../components/SEO"

import Hero from "../components/home/Hero"
import Mission from "../components/home/Mission"
import Leadership from "../components/home/Leadership"
import Instagram from "../components/home/Instagram"

const IndexPage = props => {
  const { grams, handle } = props
  const { data } = props.data.prismicHomepage
  return (
    <>
      <SEO title="AND Campaign | Charleston" />
      <div className="page">
        <div className="page-inner">
          <Hero
            heroImage={data.hero_image}
            heroImage2={data.hero_image_2}
            heroHeadline={data.headline}
            heroSubheadline={data.subheadline}
            heroYoutube={data.hero_youtube_video_id.text}
            heroCtaText={data.cta_text.text}
            heroCtaText2={data.cta_text_2.text}
            heroCtaUrl={data.cta_url.raw.url}
            heroCtaUrl2={data.cta_url_2.raw.url}
          />
          <Mission image={data.mission_image} bodyCopy={data.body_copy} />
          <Leadership people={data.people} />
          <Instagram grams={grams} handle={handle} />
        </div>
      </div>
    </>
  )
}

export default IndexPage

export const query = graphql`
  query {
    prismicHomepage {
      data {
        headline {
          text
        }
        hero_image {
          fluid(maxWidth: 2400) {
            src
          }
          url
        }
        hero_image_2 {
          fluid(maxWidth: 2400) {
            src
          }
          url
        }
        subheadline {
          text
        }
        cta_text {
          text
        }
        cta_url {
          raw
        }
        cta_text_2 {
          text
        }
        cta_url_2 {
          raw
        }
        hero_youtube_video_id {
          text
        }
        mission_image {
          fluid(maxWidth: 2400) {
            src
          }
          url
        }
        body_copy {
          html
        }
        people {
          name {
            text
          }
          headshot {
            url
          }
          bio {
            html
          }
        }
      }
    }
  }
`
