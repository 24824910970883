import React from "react"
import Img from "gatsby-image"
import YouTube from "react-youtube"

import style from "./hero.module.scss"

const Hero = props => {
  const {
    heroImage,
    heroImage2,
    heroHeadline,
    heroSubheadline,
    heroYoutube,
    heroCtaText,
    heroCtaUrl,
    heroCtaText2,
    heroCtaUrl2,
  } = props
  const opts = {
    height: "390",
    width: "640",
    controls: 0,
    modestbranding: 1,
  }

  return (
    <section
      className={`${style.hero} flex align-center`}
      // style={{ background: `url(${heroImage.url}) bottom/cover no-repeat` }}
    >
      <div className={`${style.wrapper} wrapper`}>
        <div className="flex align-center">
          <div className={`${style.left} inner`}>
            <h1 className="caps" style={{ marginBottom: "5px" }}>
              AND Campaign | Charleston
            </h1>
            <h2 className={`${style.headline} futura-font uppercase text-lg`}>
              {heroHeadline.text}
            </h2>
            <h3 className="text-md">{heroSubheadline.text}</h3>
            <div className="flex">
              <a
                href={heroCtaUrl}
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginBottom: "15px", marginRight: "15px" }}
              >
                {heroCtaText}
              </a>
              <a
                href={heroCtaUrl2}
                className="btn"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginBottom: "15px" }}
              >
                {heroCtaText2}
              </a>
            </div>
          </div>
          <div className={style.right}>
            <div className={style.image_1}>
              <Img fluid={heroImage.fluid} />
            </div>
            <div className={style.video}>
              <YouTube
                containerClassName={"resp-container"}
                videoId={heroYoutube}
                opts={opts}
              />
            </div>
            <div className={style.image_2}>
              <Img fluid={heroImage2.fluid} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
