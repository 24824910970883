import React from "react"
import Img from "gatsby-image"
import parse from "html-react-parser"

import style from "./mission.module.scss"

const Mission = props => {
  const { image, bodyCopy } = props
  return (
    <section
      className={`${style.mission} pad`}
      style={{ position: "relative" }}
    >
      <div className="wrapper flex">
        <div className={`${style.left} one-half`}>
          <div className={`${style.image} show_860`}>
            <Img fluid={image.fluid} />
          </div>
        </div>
        <div className={`${style.right} one-half`}>
          <div className={`${style.text} inner col`}>
            {parse(bodyCopy.html)}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mission
