import React from "react"

import style from "./instagram.module.scss"

const Instagram = props => {
  const { grams, handle } = props
  return (
    <section className={`${style.instagram} pad no_pad_top`}>
      <div className="wrapper">
        <a
          href={`https://www.instagram.com/${handle}`}
          target="_blank"
          className={`${style.handle} caps`}
          style={{ marginBottom: "20px", display: "inline-block" }}
        >
          @{handle}
        </a>
        <div className={`flex grid five ${style.grid}`}>
          {grams[0]?.map(({ node }) => {
            return (
              <a
                href={`https://www.instagram.com/p/${node.shortcode}`}
                className={`grid-item one-fifth ${style.gram}`}
                key={node.id}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className={style.overlay}></span>
                <img
                  src={node.thumbnail_src}
                  alt={node.accessibility_caption}
                  className={style.image}
                />
              </a>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default Instagram
