import React, { useState } from "react"
import parse from "html-react-parser"
import { isMobile } from "react-device-detect"

import style from "./leadership.module.scss"

const Leadership = props => {
  //   const [hovered, hasHovered] = useState(false)
  const { people } = props
  const [activePerson, setActivePerson] = useState(people[0].name.text)
  const [bio, setBio] = useState(people[0].bio.html)
  return (
    <section className={`pad no_pad_top`}>
      <div className="wrapper content">
        <div className="flex">
          <div className={`two-fifths ${style.left}`}>
            <h2 className={style.title}>
              <span>Leadership</span>
            </h2>
            <div className={`inner ${style.bio_container}`}>
              <h3 style={{ marginBottom: "15px" }}>{activePerson}</h3>
              <div className={style.bio}>{parse(bio)}</div>
            </div>
          </div>
          <div className={`three-fifths ${style.right}`}>
            <div className={`flex grid three ${style.people}`}>
              {people.map((person, index) => {
                return (
                  <div
                    key={index}
                    className={style.person}
                    onClick={() => {
                      setActivePerson(person.name.text)
                      setBio(person.bio.html)
                    }}
                  >
                    <img
                      src={person.headshot.url}
                      className={`${style.headshot} ${
                        activePerson === person.name.text
                          ? style.active
                          : style.inactive
                      }`}
                      alt={person.name.text}
                    />
                    <h3 className="caps">{person.name.text}</h3>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Leadership
